import React from 'react';
import { Layout, Row, Col, Avatar, Typography } from 'antd';
import './AboutsPage.css';
import chplay from '../assets/images/chplay.jpg';
import appstore from '../assets/images/appstore.jpg';
import avatar from '../assets/images/logo.jpg';

const { Title, Text } = Typography;

const calculateWorkDuration = (startDate) => {
  const start = new Date(startDate);
  const now = new Date();
  const diff = now - start; // chênh lệch thời gian tính bằng ms

  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));

  return `${years} năm ${months} tháng`;
};

const AboutsPage = () => {
  const workDuration = calculateWorkDuration('2021-10-01');
  return (
    <Layout className="aboutpage">
      {/* Profile Section */}
      <Row gutter={16}>
        <Col span={8}>
          <Avatar size={120} src={avatar} />
          <Title level={4} style={{ marginTop: '10px' }}>Nguyễn Văn Minh</Title>
          <Text>Kỹ sư phần mềm</Text>
        </Col>
      </Row>

      {/* Education Section */}
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={8}>
          <Title level={3}>Trình độ học vấn</Title>
          <Text>Trường đại học Bách Khoa - Đại học Quốc gia Thành phố Hồ Chí Minh</Text>
          <br />
          <Text>Chuyên ngành: Khoa học máy tính</Text>
        </Col>
      </Row>

      {/* Work Experience Section */}
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={8}>
          <Title level={3}>Kinh nghiệm làm việc</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Title level={4}>CÔNG TY CỔ PHẦN CÔNG NGHỆ TRƯỜNG MINH THỊNH</Title>
          <Text>Chức vụ: Nhân viên lập trình</Text>
          <br />
          <Text>Thời gian làm việc: {workDuration}</Text>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Title level={5}>Ứng dụng WionPos</Title>
        </Col>
      </Row>
      {/* Download Links Section */}
      <Row gutter={16}>
        <Col span={1}>
          <a href="https://play.google.com/store/apps/details?id=asia.tmtco.wion.pos&hl=vi" target="_blank" rel="noopener noreferrer">
            <img src={chplay} alt="Google Play" style={{ width: '50px', marginBottom: '10px' }} />
          </a>
        </Col>
        <Col span={1}>
          <a href="https://apps.apple.com/vn/app/wion-pos/id6468011285" target="_blank" rel="noopener noreferrer">
            <img src={appstore} alt="App Store" style={{ width: '50px' }} />
          </a>
        </Col>
      </Row>
    </Layout>
  );
};

export default AboutsPage;
