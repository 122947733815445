import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeOutlined, SolutionOutlined, ToolOutlined, SmileOutlined, WindowsOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import './Header.css';

// Danh sách menu
const items = [
  {
    label: 'Home',
    key: '/',
    icon: <HomeOutlined />
  },
  {
    label: 'Abouts me',
    key: '/abouts',
    icon: <SolutionOutlined />
  },
  {
    label: 'My tools',
    key: '/tools',
    icon: <ToolOutlined />
    // children: [
    //   {  key: '/tools/find-music' , label: 'Tool 1' },
    //   {  key: '/tools/find-music-1' , label: 'Tool 2' },
    //   {  key: '/tools/find-music-2' , label: 'Tool 3' }
    // ]
  },
  {
    label: 'Weekend relax',
    key: '/relax',
    icon: <SmileOutlined />
  },
  {
    label: 'Workspace',
    key: '/workspace',
    icon: <WindowsOutlined />
  }
];

const Header = () => {
  const [current, setCurrent] = useState('/');
  const navigate = useNavigate();

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key); // Điều hướng tới route tương ứng
  };

  return (
    <header className="header-container">
      {/* Menu chính */}
      <div className="header-menu-desktop">
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </div>
    </header>
  );
};

export default Header;
